import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ZoneDetails} from '../ZoneDetails';

@Component({
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.css']
})
export class RegistrationDetailsComponent implements OnInit, OnChanges {
  @Input() kbaDetails: ZoneDetails;

  chartType = 'LineChart';

  data = [];
  options = {
    hAxis: {
      title: 'Year/Month',
      format: 'YYYY-MM',
      gridlines: {
        count: 10
      },
      textStyle: {
        fontSize: 14
      },
      titleTextStyle: {
        fontSize: 14,
        italic: false
      }
    },
    vAxis: {
      title: 'Electric Vehicles',
      textStyle: {
        fontSize: 14
      },
      titleTextStyle: {
        fontSize: 14,
        italic: false
      }
    },
    legend : {
      position: 'none'
    },
    titleTextStyle: {
      fontSize: 0
    },
    chartArea: {
      width: '80%',
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.kbaDetails.currentValue){
      return;
    }
    const currentValue = changes.kbaDetails.currentValue;
    this.kbaDetails = currentValue;

    this.data = [];
    for (const record of currentValue.registrations) {
      this.data.push([new Date(record.year, record.month), record.noVehicles]);
    }

  }

}
