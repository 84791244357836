<div id="controls">
  <div id="controls-inner">
    <span class="control-heading">
      <input type="checkbox" #modeKBABox [checked]="modeKBA" (change)="updateModeKBA(modeKBABox.checked);" />
      Electric Vehicle Registration Data</span><br />
    <span class="kba-rights">Datenquelle: Kraftfahrt-Bundesamt, Flensburg 2021</span>
    <hr>
    <span class="control-heading">
      <input type="checkbox" #modeDemandBox [checked]="modeDemand" (change)="updateModeDemand(modeDemandBox.checked)"/>
      Charging Demand Estimation</span><br />
    <span class="sub-control"><input type="checkbox" #demandUseStationsBox [checked]="demandUseStations" (change)="updateDemandUseStations(demandUseStationsBox.checked)"/>
    Existing charging stations <span class="red-dot"></span></span>

    <span class="sub-control"><input type="checkbox" #demandUseTrips [checked]="demandUseTrips" (change)="updateDemandUseTrips(demandUseTrips.checked)"/>
    FCD Trip Data</span>

    <span class="sub-control"><input type="checkbox" #demandUseKBA [checked]="demandUseKBA" (change)="updateDemandUseKBA(demandUseKBA.checked)"/>
    KBA Vehicle Registrations</span>

    <!-- <button class="btn clear Stations" (click)="clearStationNearClick()"> Clear stations</button> -->

    <hr>
    <span class="control-heading">
      <input type="checkbox" #modeDynamicDemandBox [checked]="modeDynamicDemand" (change)="updateModeDynamicDemand(modeDynamicDemandBox.checked)"/>
      Dynamic Charging Demand Estimation</span><br />

    <form>
      <mat-form-field appearance="fill">
        <mat-label>Choose a start date</mat-label>
        <input matInput [matDatepicker]="picker" (dateInput)="updateDateStartPos('input', $event)">
        <mat-hint>DD-MMM-YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startCalendarDate"></mat-datepicker>
      </mat-form-field>
    </form>

    <mat-slider
      thumbLabel
      [displayWith]="formatSliderLabel"
      [tickInterval]="sliderDelta"
      [step]="sliderDelta"
      [min]="startDate"
      [max]="endDate"
      [(ngModel)]="sliderValue"
      aria-label="units"
      (input)="updateDemandUpdateIndex($event)">
    </mat-slider>

    <div class="d-flex justify-content-center mt-2">
      <button class="btn btn-primary mr-2" (click)="pauseClick()" *ngIf="!isComplete">Pause</button>
      <button class="btn btn-primary mr-2" (click)="restartClick()" *ngIf="!isComplete && !isComplete">Play</button>

      <button class="btn btn-success ml-2" (click)="restartClick()" *ngIf="isComplete">Restart</button>
    </div>

    {{sliderValue | date:'dd/MM/yyyy(EEE) - hh:mmaaa'}}

  </div>
</div>
