import {Component, OnInit} from '@angular/core';

import {ModelService} from '../model.service';

import {ZoneDetails} from '../ZoneDetails';
import * as L from 'leaflet';
import {Observable} from 'rxjs';
import {interval} from 'rxjs';


@Component({
  selector: 'app-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['./main-map.component.css']
})
export class MainMapComponent implements OnInit {
  private map: L.Map;

  kbaDetails: ZoneDetails;

  private kbaLayers: L.Layer[] = [];
  private kbaLegend: L.Control;
  private modeKBA: boolean;


  private demandLayers: { [id: number]: L.Layer } = {};
  private demandStations: L.Layer[] = [];
  private demandLegend: L.Control;
  private modeDemand: boolean;
  private demandInitDone = false;
  private showChargingStations = false;

  private demandColorValues: [number, string][] = [
    [8 , '#045a8d'],
    [6, '#74a9cf'],
    [4, '#a6bddb'],
    [2, '#d0d1e6'],
    [0, '#f1eef6']
  ];

  private scoreChargingStation = new Map<number, string>([
    [1, '#2ec20a'],
    [2, '#45dd12'],
    [3, '#90EE91'],
    [4, '#BFF4BE'],
    [5, '#EDF9EB']
  ]);

  private LeafIcon = L.Icon.extend({
    options: {
      shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png',
      iconSize:     [38, 95],
      shadowSize:   [50, 64],
      iconAnchor:   [22, 94],
      shadowAnchor: [4, 62],
      popupAnchor:  [-3, -76]
    }
  });

  private scoreChargingStationMarker = new Map<number, string>([
    // [1, 'http://leafletjs.com/examples/custom-icons/leaf-green.png'],
    // [2, 'http://leafletjs.com/examples/custom-icons/leaf-orange.png'],
    // [3, 'http://leafletjs.com/examples/custom-icons/leaf-red.png'],
    // [4, 'http://leafletjs.com/examples/custom-icons/leaf-red.png'],
    // [5, 'http://leafletjs.com/examples/custom-icons/leaf-red.png']
    [1, 'assets/iconChargingStation_green.png'],
    [2, 'assets/iconChargingStation_yellow.png'],
    [3, 'assets/iconChargingStation_red.png'],
    [4, 'assets/iconChargingStation_red.png'],
    [5, 'assets/iconChargingStation_red.png']
  ]);
  // the function of calculating score is not fully debugged.
  private scoreStep = 30;
  private scoreMarkerRadius = 25;

  private dynamicDemandValueStorage: { [id: number]: number[] } = {};
  private fillInitDays = 7;
  private modeDynamicDemand: boolean;
  private demandStationsNearClick: L.Layer[] = [];
  private demandStationsNearClickMarkers: L.Layer[] = [];

  // private myIcon = L.icon({
  //   iconUrl: 'file:///C:/Users/Evgeniy/WebstormProjects/web-frontend3/src/app/main-map/iconChargingStation.png', //iconChargingStation.png',
  //   iconSize: [38, 95],
  //   iconAnchor: [22, 94],
  //   popupAnchor: [-3, -76],
  // });

  // Ping server every 1000 ms, and wait for new damandValues
  // private observableRef = interval(1000) // TODO uncomment, or delete, of we don't do live updates
  //   .subscribe(() => {
  //     this.getDynamicDemanValuesFromServer();
  //   });

  constructor(private modelService: ModelService) {
  }

  ngOnInit(): void {
    this.initMap();

    this.modelService.getDemandUseStations().subscribe(value => {
      this.showChargingStations = value;
      (this.showChargingStations) ? this.drawStations() : this.hideStations();
    });

    this.modelService.getKBAZones().subscribe(value => {
      this.addKBAZones(value);
      this.updateKBALayer();
    });

    this.modelService.getModeKBA().subscribe(modeKBA => {
      this.modeKBA = modeKBA;
      this.updateKBALayer();
    });

    this.modelService.getDemandGrid().subscribe(value => {
      // this.initFillDynamicDemandValuesStorage();
      this.getDemanValuesFromServerFromDb(); // currently it is fast, but maybe, in case of more data, we should wait.
      this.addDemandGrid(value);
      this.addChargingStations();
      this.updateDemandValues();
      this.updateDynamicDemandValues();
      this.updateDemandLayer();
      this.demandInitDone = true;
    });

    this.modelService.getModeDemand().subscribe(modeDemand => {
      this.modeDemand = modeDemand;
      this.updateDemandLayer();
    });

    this.modelService.getModeDynamicDemand().subscribe(modeDynamicDemand => {
      this.modeDynamicDemand = modeDynamicDemand;
      this.updateDynamicDemandLayer();
    });

    this.modelService.getDemandChanged().subscribe(x => {
      if (this.demandInitDone) {
        if (this.modeDemand) {
          this.updateDemandValues();
        }
        if (this.modeDynamicDemand) {
          this.updateDynamicDemandValues();
        }
      }
    });

    /*
    this.modelService.getClearStationsNearClick().subscribe(doClearStationsNearClick => {
      for (const station of this.demandStationsNearClick) {
        this.map.removeLayer(station);
      }
      for (const station of this.demandStationsNearClickMarkers) {
        this.map.removeLayer(station);
      }
      this.demandStationsNearClick = [];
      this.demandStationsNearClickMarkers = [];
    });

    if (this.modeDemand && this.showChargingStations) {
      this.map.on('click', e => {
        console.log(e.latlng); // get the coordinates
        for (const station of this.demandStationsNearClick) {
          this.map.removeLayer(station);
        }
        for (const station of this.demandStationsNearClickMarkers) {
          this.map.removeLayer(station);
        }
        this.demandStationsNearClick = [];
        this.demandStationsNearClickMarkers = [];

        // markers for click (start) location
        const startPosMarker = new L.Marker([e.latlng.lat, e.latlng.lng]); // add the onclick-marker startLocation
        this.demandStationsNearClickMarkers.push(startPosMarker);

        this.modelService.addClick(e.latlng.lat, e.latlng.lng).subscribe(response => {
          for (const chargePoint of response['response']) {
            console.log((chargePoint));
            const chargePointPriority = this.calcChargePointPriority(chargePoint.score);

            // markers for stations nearby
            const curLeafIcon = new this.LeafIcon({iconUrl: this.scoreChargingStationMarker.get(chargePointPriority)});
            const stationMarker = new L.Marker([chargePoint.Lat, chargePoint.Lng], {icon: curLeafIcon}); // add the marker onclick

            this.demandStationsNearClickMarkers.push(stationMarker);
            // circles
            const circle = L.circle([chargePoint.Lat, chargePoint.Lng], {
              color: 'green',
              fillColor: this.scoreChargingStation.get(chargePointPriority),
              radius: this.scoreMarkerRadius
            });
            this.demandStationsNearClick.push(circle);
          }
          for (const station of this.demandStationsNearClick) {
            station.addTo(this.map);
          }
          for (const station of this.demandStationsNearClickMarkers) {
            station.addTo(this.map);
          }
        });
      });
    }*/
    // L.marker([e.latlng.lat, e.latlng.lng], this.markerIcon).addTo(this.map); // add the marker onclick
  }

  /*private calcChargePointPriority(chargePointScore: number): number {
    const lowestPriority = this.scoreChargingStation.size;
    const chargePointPriority = Math.floor(chargePointScore / this.scoreStep);
    if (chargePointPriority <= lowestPriority) {
      return chargePointPriority;
    }
    return lowestPriority;
  }*/

  private updateDemandLayer(): void {
    if (this.modeDemand) {
      this.updateDemandValues();
      for (const [id, currentLayer] of Object.entries(this.demandLayers)) {
        currentLayer.addTo(this.map);
      }
      if (this.showChargingStations) {
        this.drawStations();
      }
      // if (this.showStationsNearClick) {
      //   this.drawStationsNearClick();
      // }
      if (this.demandLegend) {
        this.demandLegend.addTo(this.map);
      }
    } else {
      for (const [id, currentLayer] of Object.entries(this.demandLayers)) {
        this.map.removeLayer(currentLayer);
      }
      this.hideStations();
      if (this.demandLegend) {
        this.map.removeControl(this.demandLegend);
      }
    }
  }

  private updateDynamicDemandLayer(): void {
    if (this.modeDynamicDemand) {
      this.updateDynamicDemandValues();
      for (const [id, currentLayer] of Object.entries(this.demandLayers)) {
        currentLayer.addTo(this.map);
      }
      if (this.showChargingStations) {
        this.drawStations();
      }
      if (this.demandLegend) {
        this.demandLegend.addTo(this.map);
      }
    } else {
      for (const [id, currentLayer] of Object.entries(this.demandLayers)) {
        this.map.removeLayer(currentLayer);
      }
      this.hideStations();
      if (this.demandLegend) {
        this.map.removeControl(this.demandLegend);
      }
    }
  }

  private updateDemandValues(): void {
    this.modelService.getDemandValues().subscribe(v => {
      for (const [id, value] of Object.entries(v)) {
        this.demandLayers[id].unbindTooltip();
        this.demandLayers[id].setStyle({fillColor: getColorFromColorMap(this.demandColorValues, value)});
        this.demandLayers[id].bindTooltip((Math.round(value * 100) / 100).toString(), {
          direction: 'top'
        });
      }
    });
  }

  // private initFillDynamicDemandValuesStorage(): void {
  //   // imitates already stored data from the model.
  //   for (let dataUpdate = 1577833200000; dataUpdate < 1577833200000 + (86400000 * this.fillInitDays); dataUpdate += 86400000) {
  //     this.dynamicDemandValueStorage[dataUpdate] = [];
  //     console.log(dataUpdate);
  //     for (let j = 0; j <= 373; j++) {
  //       this.dynamicDemandValueStorage[dataUpdate].push(j / 37);
  //     }
  //   }
  // }

  private updateDynamicDemandValuesStorage(v: { [id: number]: number[] }): void {
    // add new model prediction to the storage
    for (const [id, value] of Object.entries(v)) {
      const dateKey = Number(id);
      // console.log(dateKey);
      this.dynamicDemandValueStorage[dateKey] = [];
      this.dynamicDemandValueStorage[dateKey] = this.dynamicDemandValueStorage[dateKey].concat(value);
    }
  }

  private async getDynamicDemanValuesFromServer(): Promise<void> {
    try {
      this.modelService.getDemandValuesOnline().subscribe(v => {
        this.updateDynamicDemandValuesStorage(v);
      });
    } catch (err) {
      console.log(err);
    }
  }

  private updateDemandValuesStorageFromDb(v: { [id: number]: number[] }): void {
    // add multiple model prediction from DB to the storage
    for (const [id, value] of Object.entries(v)) {
      const dateKey = Number(id);
      // console.log(dateKey);
      this.dynamicDemandValueStorage[dateKey] = [];
      this.dynamicDemandValueStorage[dateKey] = this.dynamicDemandValueStorage[dateKey].concat(value);
    }
  }

  private async getDemanValuesFromServerFromDb(): Promise<void> {
    try {
      this.modelService.getAllDemandValuesFromDB().subscribe(v => {
        this.updateDemandValuesStorageFromDb(v);
      });
    } catch (err) {
      console.log(err);
    }
  }

  private updateDynamicDemandValues(): void {
    const visualisationDate = this.modelService.getDemandVisualisationDate();
    console.log(visualisationDate);
    console.log(this.dynamicDemandValueStorage[visualisationDate]);
    for (let id = 0; id <= 373; id++) {
      let demandValue = 0;
      try {
        demandValue = this.dynamicDemandValueStorage[visualisationDate][id];
      } catch (error) {
        console.log('No demandValue info on this date', visualisationDate);
      }
      this.demandLayers[id].unbindTooltip();
      this.demandLayers[id].setStyle({fillColor: getColorFromColorMap(this.demandColorValues, demandValue)});
      this.demandLayers[id].bindTooltip((Math.round(demandValue * 100) / 100).toString(), {
        direction: 'top'
      });
    }
  }

  private updateKBALayer(): void {
    if (this.modeKBA) {
      for (const currentLayer of this.kbaLayers) {
        currentLayer.addTo(this.map);
      }
      if (this.kbaLegend) {
        this.kbaLegend.addTo(this.map);
      }
    } else {
      for (const currentLayer of this.kbaLayers) {
        this.map.removeLayer(currentLayer);
        this.kbaDetails = null;
      }
      if (this.kbaLegend) {
        this.map.removeControl(this.kbaLegend);
      }
    }
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [52.3224346555334, 9.81649473554199],
      zoom: 12
    });

    const at = 'pk.eyJ1Ijoibmljb2xhc3RlbXBlbG1laWVyIiwiYSI6ImNrYnJ4djlvYTMwcXgzMHBqNTRlMm81b24ifQ.WwPgH3YN2j6tyGLgGQwwXQ';
    // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    const tiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token='
      + at, {
      maxZoom: 19,
      attribution: '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);

  }

  private addDemandGrid(value: { [id: number]: L.Layer }): void {
    for (const [id, cell] of Object.entries(value)) {
      this.demandLayers[id] = L.geoJson(cell, {
        style: {
          weight: 0.3,
          opacity: 1,
          color: 'black',
          fillOpacity: 0.4
        }
      });
    }

    const legend = L.control({position: 'bottomright'});
    legend.onAdd = map => {
      const div = L.DomUtil.create('div', 'info legend');
      // loop through our density intervals and generate a label with a colored square for each interval
      for (let i = 0; i < this.demandColorValues.length; i++) {
        div.innerHTML += '<i style="background:' + getColorFromColorMap(this.demandColorValues, this.demandColorValues[i][0] + 1) + '"></i> ';
        if (i === 0) {
          div.innerHTML += '>' + this.demandColorValues[i][0];
        } else if (i === this.demandColorValues.length - 1) {
          div.innerHTML += '0  &ndash; ' + (this.demandColorValues[i - 1][0]);
        } else {
          div.innerHTML += this.demandColorValues[i][0] + ' &ndash; ' + (this.demandColorValues[i - 1][0]);
        }
        div.innerHTML += '<br>';
      }

      return div;
    };

    this.demandLegend = legend;
  }

  private addChargingStations(): void {
    this.modelService.getChargingStations().subscribe(stationsList => {
      for (const station of stationsList) {
        const circle = L.circle(station, {
          color: 'red',
          fillColor: '#f03',
          radius: 7
        });
        this.demandStations.push(circle);
        if (this.modeDemand && this.showChargingStations) {
          circle.addTo(this.map);
        }
      }
    });
  }

  private drawStations(): void {
    for (const station of this.demandStations) {
      station.addTo(this.map);
    }

    for (const station of this.demandStationsNearClick) {
      station.addTo(this.map);
    }

    for (const station of this.demandStationsNearClickMarkers) {
      station.addTo(this.map);
    }
  }

  private hideStations(): void {
    for (const station of this.demandStations) {
      this.map.removeLayer(station);
    }

    for (const station of this.demandStationsNearClick) {
      this.map.removeLayer(station);
    }

    for (const station of this.demandStationsNearClickMarkers) {
      this.map.removeLayer(station);
    }
  }

  // private drawStationsNearClick(): void {
  //   for (const station of this.demandStationsNearClick) {
  //     station.addTo(this.map);
  //   }
  // }
  //
  // private hideStationsNearClick(): void {
  //   for (const station of this.demandStationsNearClick) {
  //     this.map.removeLayer(station);
  //   }
  // }

  private addKBAZones(value): void {
    const kbaColors: string[] = ['#045a8d', '#2b8cbe', '#74a9cf', '#a6bddb', '#d0d1e6', '#f1eef6'];
    let maxRegistration = 0;
    for (const v of value) {
      maxRegistration = Math.max(maxRegistration, v[2]);
    }

    const binSize = Math.round(maxRegistration / (kbaColors.length));
    const kbaLimits: [number, string][] = [];
    for (let i = 0; i < kbaColors.length; i++) {
      const currentLimit = maxRegistration - (i + 1) * binSize;
      kbaLimits.push([currentLimit, kbaColors[i]]);
    }

    const legend = L.control({position: 'bottomright'});
    legend.onAdd = map => {
      const div = L.DomUtil.create('div', 'info legend');
      // loop through our density intervals and generate a label with a colored square for each interval
      for (let i = 0; i < kbaLimits.length; i++) {
        div.innerHTML += '<i style="background:' + getColorFromColorMap(kbaLimits, kbaLimits[i][0] + 1) + '"></i> ';
        if (i === 0) {
          div.innerHTML += '>' + kbaLimits[i][0];
        } else if (i === kbaLimits.length - 1) {
          div.innerHTML += '0  &ndash; ' + (kbaLimits[i - 1][0] - 1);
        } else {
          div.innerHTML += kbaLimits[i][0] + ' &ndash; ' + (kbaLimits[i - 1][0] - 1);
        }
        div.innerHTML += '<br>';
      }

      return div;
    };

    this.kbaLegend = legend;

    for (const v of value) {
      function kbaStyle(feature): any {
        return {
          weight: 0.3,
          opacity: 1,
          color: 'black',
          dashArray: '3',
          fillOpacity: 0.4,
          fillColor: getColorFromColorMap(kbaLimits, v[2]),
        };
      }

      const currentLayer = L.geoJson(v[1], {
        style: kbaStyle,
        onEachFeature: (feature, layer) => {
          layer.on('click', e => {
            const zoneDetails: Observable<ZoneDetails> = this.modelService.getZoneDetails(v[0]);
            zoneDetails.subscribe((zd: ZoneDetails): void => {
              this.kbaDetails = zd;
            });
          });
        }
      });
      currentLayer.bindTooltip((Math.round(v[2] * 100) / 100).toString(), {
        direction: 'top'
      });

      this.kbaLayers.push(currentLayer);
    }
  }
}


function getColorFromColorMap(limits: [number, string][], value: number): string {
  for (let i = 0; i < limits.length; i++) {
    const currentLimit = limits[i][0];
    const currentColor = limits[i][1];
    if (i === 0) {
      if (value > currentLimit) {
        return currentColor;
      }
    } else if (i === limits.length - 1) {
      return currentColor;
    } else {
      if (value > currentLimit) {
        return currentColor;
      }
    }
  }
}
