<div *ngIf="kbaDetails"  id="kbaDetails">
  <span>{{kbaDetails.zipCode}}</span>
  <span>{{kbaDetails.name}}</span>
  <span>{{kbaDetails.allRegistrations}} overall registrations</span>
  <google-chart id="chart"
                [type]="chartType"
                [data]="data"
                [options]="options"
                [dynamicResize]="true">
  </google-chart>
</div>

