import {Component, OnInit} from '@angular/core';
import {Options} from '@angular-slider/ngx-slider';

import {ModelService} from '../model.service';
import {DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter} from '@angular/material/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MatSliderChange} from '@angular/material/slider';
import {formatDate} from '@angular/common';
import {interval, Observable, PartialObserver, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd-MMM-yyyy', this.locale);
    } else {
      return date.toDateString();
    }
  }
}

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css'],
  providers: [
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
  ]
})
export class ControlPanelComponent implements OnInit {

  modeKBA = true;
  modeDemand = false;
  demandUseStations = true;
  demandUseTrips = true;
  demandUseKBA = true;

  modeDynamicDemand = false;
  startDate = 1561939200000; // 2019/7/1 //1577833200000; // 1/1/2020  // 1561939200000
  oneDay = 86400000;
  sliderDelta = 1800000; // 30 minutes
  // dayRange = 30;
  endDate = this.startDate + this.oneDay; // * this.dayRange;
  startCalendarDate = new Date(2019, 6, 1); // Calendar starts month from 0 (attention! on Backend, the month starts from 1)
  sliderValue = this.startDate;

  isRunning = false;
  isComplete = false;
  pauseClick$ = new Subject();
  stopClick$ = new Subject();
  private progressNum = 0;
  progress: string;
  timer$: Observable<number>;
  timerObserver: PartialObserver<number>;

  zoomLevel = 12;
  zoomOptions: Options = {
    floor: 1,
    ceil: 19
  };

  constructor(private modelService: ModelService) {
  }

  ngOnInit(): void {

    this.timer$ = interval(1000)
      .pipe(
        takeUntil(this.pauseClick$),
        takeUntil(this.stopClick$)
      );

    this.timerObserver = {
      next: (_: number) => {
        if ((this.sliderValue < this.endDate) && (this.isRunning)){
          this.increaseSlider();
        } else {
          this.stopClick$.next();
          this.isRunning = false;
          this.isComplete = true;
        }
      }
    };

    this.timer$.subscribe(this.timerObserver);

    this.modelService.getModeKBA().subscribe(nextKBA => {
      this.modeKBA = nextKBA;
      if (this.modeKBA && this.modeDemand) {
        this.updateModeDemand(false);
      }
      if (this.modeKBA && this.modeDynamicDemand) {
        this.updateModeDynamicDemand(false);
      }
    });

    this.modelService.getModeDemand().subscribe(nextDemand => {
      this.modeDemand = nextDemand;
      if (this.modeDemand && this.modeKBA) {
        this.updateModeKBA(false);
      }
      if (this.modeDemand && this.modeDynamicDemand) {
        this.updateModeDynamicDemand(false);
      }
    });

    this.modelService.getModeDynamicDemand().subscribe(nextDynamicDemand => {
      this.modeDynamicDemand = nextDynamicDemand;
      if (this.modeDynamicDemand && this.modeKBA) {
        this.updateModeKBA(false);
      }
      if (this.modeDynamicDemand && this.modeDemand) {
        this.updateModeDemand(false);
      }
    });

    this.modelService.getDemandUseStations().subscribe(next => {
      this.demandUseStations = next;
    });

    this.modelService.getDemandUseTrips().subscribe(next => {
      this.demandUseTrips = next;
    });

    this.modelService.getDemandUseKBA().subscribe(next => {
      this.demandUseKBA = next;
    });
  }

  updateModeKBA(v: boolean): void {
    this.modelService.setModeKBA(v);
  }

  updateModeDemand(v: boolean): void {
    this.modelService.setModeDemand(v);
  }

  updateModeDynamicDemand(v: boolean): void {
    this.modelService.setModeDynamicDemand(v);
  }

  updateDemandUseStations(v: boolean): void {
    this.modelService.setDemandUseStations(v);
  }

  updateDemandUseTrips(v: boolean): void {
    this.modelService.setDemandUseTrips(v);
  }

  updateDemandUseKBA(v: boolean): void {
    this.modelService.setDemandUseKBA(v);
  }

  updateDateStartPos(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.startDate = event.value.getTime();
    this.endDate = this.startDate + this.oneDay; // * 31;

    // for playbutton logig (stop it)
    this.isRunning = false;
    this.pauseClick$.next();
    // for slider logic (set it to the beginning
    this.sliderValue = this.startDate;
    // update Demand Visualization Map
    this.updateDemandUpdateIndexByNumber(this.sliderValue);
  }

  formatSliderLabel(value: number): string {
    const msToDate = new Date(value);
    return msToDate.toLocaleDateString('en-GB');
  }

  updateDemandUpdateIndex(event: MatSliderChange): void {
    this.modelService.setDemantVisualisationDate(event.value);
  }

  updateDemandUpdateIndexByNumber(value: number): void {
    this.modelService.setDemantVisualisationDate(value);
  }

  clearStationNearClick(): void {
    this.modelService.setClearStationsNearClick();
  }

  pauseClick(): void {
    this.pauseClick$.next();
    this.isRunning = false;
  }

  restartClick(): void {
    this.isRunning = true;
    if (this.isComplete) {
      this.isComplete = false;
      this.sliderValue = this.startDate;
      // this.getProgress();
    }

    this.timer$.subscribe(this.timerObserver);

    // update Demand Visualization Map
    this.updateDemandUpdateIndexByNumber(this.sliderValue);
  }

  increaseSlider(): void {
    this.sliderValue += this.sliderDelta; //oneDay;
    this.updateDemandUpdateIndexByNumber(this.sliderValue);
  }
}
