import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgxSliderModule } from '@angular-slider/ngx-slider';


import { AppComponent } from './app.component';
import { MainMapComponent } from './main-map/main-map.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import {HttpClientModule} from '@angular/common/http';
import { RegistrationDetailsComponent } from './registration-details/registration-details.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatDatepickerModule } from '@angular/material/DATEPICKER';
// import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
// import {MatButtonModule} from '@angular/material/button';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {FormsModule} from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';



@NgModule({
  declarations: [
    AppComponent,
    MainMapComponent,
    ControlPanelComponent,
    RegistrationDetailsComponent
  ],
  imports: [
    BrowserModule,
    NgxSliderModule,
    HttpClientModule,
    GoogleChartsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    // MatFormFieldModule,
    // MatDatepickerModule,
    // MatCardModule,
    MatInputModule,
    FormsModule,
    MatNativeDateModule,
    MatSliderModule,
    // MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
