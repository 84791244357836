import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ZoneDetails} from './ZoneDetails';
import {catchError} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  private zoomLevel = new BehaviorSubject<number>(0);
  private backendUrl = 'https://demand-api.l3s.uni-hannover.de';
  private modeKBA = new BehaviorSubject<boolean>(false);
  private modeDemand = new BehaviorSubject<boolean>(true);

  private demandChanged = new BehaviorSubject<boolean>(null);
  private demandUseStations = new BehaviorSubject<boolean>(true);
  private demandUseTrips = new BehaviorSubject<boolean>(true);
  private demandUseKBA = new BehaviorSubject<boolean>(true);

  private demandVisualisationDate = 1561939200000; // this was used to the visualise "random" model predictions. Todo: delete
  private modeDynamicDemand = new BehaviorSubject<boolean>(false);
  private isClearStationsNearClick = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  getModeKBA(): Observable<boolean> {
    return this.modeKBA;
  }

  setModeKBA(next: boolean): void {
    this.modeKBA.next(next);
  }

  getModeDemand(): Observable<boolean>{
    return this.modeDemand;
  }

  setModeDemand(next: boolean): void {
    this.modeDemand.next(next);
  }

  getModeDynamicDemand(): Observable<boolean>{
    return this.modeDynamicDemand;
  }

  setModeDynamicDemand(next: boolean): void {
    this.modeDynamicDemand.next(next);
  }

  getClearStationsNearClick(): Observable<boolean>{
    return this.isClearStationsNearClick;
  }

  setClearStationsNearClick(): void {
    this.isClearStationsNearClick.next(false);
  }

  getDemandUseStations(): Observable<boolean> {
    return this.demandUseStations;
  }

  setDemandUseStations(next: boolean): void {
    this.demandUseStations.next(next);
    this.demandChanged.next(true);
  }

  getDemandUseTrips(): Observable<boolean> {
    return this.demandUseTrips;
  }

  setDemandUseTrips(next: boolean): void {
    this.demandUseTrips.next(next);
    this.demandChanged.next(true);
  }

  getDemandUseKBA(): Observable<boolean> {
    return this.demandUseKBA;
  }

  setDemandUseKBA(next: boolean): void {
    this.demandUseKBA.next(next);
    this.demandChanged.next(true);
  }

  getDemandChanged(): Observable<boolean> {
    return this.demandChanged;
  }

  getKBAZones(): Observable<any> {
    return this.http.get<any>(this.backendUrl + '/kba_zones/');
  }

  getZoneDetails(zone: string): Observable<ZoneDetails> {
    return this.http.get<ZoneDetails>(this.backendUrl + '/zone_details/' + zone);
  }

  getDemandGrid(): Observable<any> {
    return this.http.get<any>(this.backendUrl + '/demand/grid/');
  }

  getDemandValues(): Observable<{ [id: number]: number }> {
    let path = '/demand/values';
    path += '/' + Number(this.demandUseStations.value).toString();
    path += '/' + Number(this.demandUseTrips.value).toString();
    path += '/' + Number(this.demandUseKBA.value).toString();

    return this.http.get<{ [id: number]: number }>(this.backendUrl + path);
  }

  getChargingStations(): Observable<[number, number][]> {
    return this.http.get<[number, number][]>(this.backendUrl + '/demand/stations');
  }

  getDemandValuesOnline(): Observable<{ [id: number]: number[] }> {
    const path = '/demand/modelprediction';
    return this.http.get<{ [id: number]: number[] }>(this.backendUrl + path);
  }

  getAllDemandValuesFromDB(): Observable<{ [id: number]: number[] }> {
    const path = '/demand/saved_db_modelprediction';
    return this.http.get<{ [id: number]: number[] }>(this.backendUrl + path);
  }

  setDemantVisualisationDate(visualisationDate: number): void {
    this.demandVisualisationDate = visualisationDate;
    this.demandChanged.next(true);
  }

  getDemandVisualisationDate(): number {
    return this.demandVisualisationDate;
  }

  addClick(clickLocationLat: number, clickLocationLng: number): Observable<any> {
    const location = JSON.stringify({'clickLocationLat': clickLocationLat, 'clickLocationLng': clickLocationLng});
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.backendUrl + '/userClick',
      location, httpOptions
    );
  }
}
